import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "avantgarde",
  casinoId: 264,
  GA_TRACKING: "G-9WJJP745W5",

  rivalChatGroupName: "Avantgarde Casino",
  prettyName: "Avantgarde Casino",
  contactEmail: "support@avantgardecasino.com",
  docsEmail: "documents@avantgardecasino.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/icons/welcome_promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/icons/cashback_promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/icons/raffle_promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/icons/cashtra_promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/icons/comppoint_promo.png"}
  ],

  metaDescription: "Join Avantgarde Casino and get no deposit bonus, free spins, VIP cash bonuses. Safe Online casino gaming with slots, table games, and live dealers. Fair play bitcoin casinos, iGaming sites and responsible gaming with real cash online!",
  logoName: "avantgarde-casino-online-casino-logo.png",
  logoAltText: "The bright red and blue colors of the official Avantgarde Casino logo introduce an online casino that is modern in design, presenting an innovative approach to a wide variety of slots, table games, and live dealer games. Enter the Avantgarde Casino for a cutting-edge online gaming experience."

};


